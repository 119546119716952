<template>
  <card class="instruction">
    <h3 class="instruction__title">{{ title }}</h3>
    <vimeo
      v-if="isVideo"
      :url="video.url"
      :id="video.id"
      class="instruction__video"
      :disabled="recording"
      key="video"
    />

    <ol class="list" v-else>
        <li>Sit upright with your mouth ~14 inches (36cm) from the microphone.</li>
        <li>Press <span class="instruction__success"><b>"Record"</b></span></li>
        
        <li>Inhale as deeply as you can.</li>
        <li>Forcefuly exhale as quickly as you can. Blast out the air as if you were blowing out candles.</li>
        <li>Press <span class="instruction__danger"><b>"Stop"</b></span> after your lungs are completely empty.</li>
        <li>Repeat the exhalation test three times.</li>
      <!-- <li> -->
      <!--   Sit upright with your mouth ~14 inches (36 cm) from the microphone -->
      <!-- </li> -->
      <!-- <li> -->
      <!--   Press <span class="instruction__success"><b>"Record"</b></span> -->
      <!-- </li> -->
      <!-- <li> -->
      <!--   Blast out the air as if you were blowing out candles. -->
      <!--   Repeat two more times (3 breaths total) -->
      <!-- </li> -->
      <!-- <li> -->
      <!--   Press <span class="instruction__danger"><b>"Stop"</b></span> after the third breath -->
      <!-- </li> -->
    </ol>

    <div class="instruction__links">
      <a
        v-if="isVideo"
        @click.prevent="changeView('text')"
        href="#"
        class="instruction__link"
      >
        {{ titles["text"].link }}
      </a>
      <a
        v-else
        @click.prevent="changeView('video')"
        href="#"
        class="instruction__link"
      >
        {{ titles["video"].link }}
      </a>
    </div>
  </card>
</template>

<script>
import Instructions from "@/mixins/instructions";
import Card from "@/components/card";
import { isIOS } from "@/utils/system";

export default {
  components: { Card },

  mixins: [Instructions],

  mounted() {
        if (isIOS){
          this.changeView("text");
        }
  },

  data: () => ({
    video: {
      url: "https://vimeo.com/423884862/604a1cc2db",
      id: "473659000",
    }
  }),
};
</script>
