<template>
  <card>
    <h2> Exhalation #{{ exhale_num  }} done </h2>
    <h3> Press record when you are ready for the next breath session.</h3>

  </card>

</template>

<script>

import Card from "@/components/card";
export default {
  components: { Card },

  mixins: [],

  data: () => ({

  }),

  props : 
    ["exhale_num"]

  
};
</script>
