<template>
  <card>
    <h3 class="instruction__title">Remember</h3>



    <remember class="record__remember" >
      <li>Sit upright with your mouth ~14 inches (36cm) from the microphone.</li>
      <li>Press "Record".</li>
      <li>Inhale as deeply as you can.</li>
      <li>Forcefuly exhale as quickly as you can. Blast out the air as if you were blowing out candles.</li>
      <li>Press "Stop" after your lungs are completely empty.</li>
      <li>Repeat the exhalation test three times.</li>
    </remember>

  </card>

</template>

<script>
import Instructions from "@/mixins/instructions";
import Remember from "@/components/remember";
import Card from "@/components/card";

export default {
  components: { Card },

  mixins: [Instructions, Remember],

  data: () => ({
    video: {
      url: "https://vimeo.com/423884862/604a1cc2db",
      id: "473659000",
    }
  }),

  props : {

  }
};
</script>
