<template>
  <app-layout class="page__recorder-outside">
    <template v-slot:space>
      <!-- <remember class="record__remember" v-if="recording"> -->
      <!--   <li>Sit upright with your mouth ~14 inches (36cm) from the microphone.</li> -->
      <!--   <li>Press "Record".</li> -->
      <!--   <li>Inhale as deeply as you can.</li> -->
      <!--   <li>Forcefuly exhale as quickly as you can. Blast out the air as if you were blowing out candles.</li> -->
      <!--   <li>Press "Stop" after your lungs are completely empty.</li> -->
      <!--   <li>Repeat the exhalation test three times.</li> -->
      <!-- </remember> -->

      <!-- <statusExhales/> -->
      <!-- <statusInstructions/> -->
      <statusInstructions v-if="recording && showInstructions"/>
      <statusExhales v-else-if="recording && !showInstructions"
      :exhale_num="exhales_recorded"/>
      <div v-if="!recording && audioSource" class="record__audio-description">
        Review your recording (optional). If you hear background noise or did
        not exert maximum effort, delete and record again.
      </div>
      <instructions v-else-if="!recording && !audioSource" />
      <record-shared
        @send-voice="sendHandler"
        @show-status="showStatus"
        @reset-counter="resetCounter"
        :disabled="loading"
        :min-duration="minDuration"
        :voiceKey="voiceKey"
        :route="currentRoute"
        inline
      />
    </template>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/appLayout";
import Instructions from "./components/instructions";
import statusExhales from "./components/statusExhales";
import statusInstructions from "./components/statusInstructions";
import RecordShared from "@/components/record-separation";
import RecordMixin from "@/mixins/record";
import RouteMixin from "@/mixins/routing";
import logVisiting from "@/mixins/logVisiting";

import {
  ROUTE_THREE_DEEP_BREATHS,
  ROUTE_THREE_STRONG_COUGHS,
  RECORD_MIN_DURATION_TDB,
} from "@/constants";

export default {
  name: "ThreeDeepBreaths",
  components: { AppLayout, Instructions, RecordShared, statusExhales, statusInstructions },
  mixins: [RecordMixin, statusExhales, statusInstructions , RouteMixin, logVisiting],

  computed : {
    showInstructions() {
      if (this.button_presses % 2 == 1) {
        console.log("showInstructions true")
        return true
      }
      else {
        console.log("showInstructions false")
        return false
      }

    }

  },

  data: () => ({
    voiceKey: "audio3",
    currentRoute: ROUTE_THREE_DEEP_BREATHS,
    nextRoute: ROUTE_THREE_STRONG_COUGHS,
    type: "record",
    minDuration: RECORD_MIN_DURATION_TDB,
    exhales_recorded: 0,
    button_presses: 0,
    instructions_displayed: 0,
    exhale_timestamps: [],
  }),

  methods : {
    showStatus() {
      // Called each time the "STOP/RECORD" button is called when the show-status event is fired
      // 1 time it is called is when RECORD is pressed before ust exhale
      // 2 time it is called is when STOP is pressed after 1st exhale
      // 3 time it is called is when RECORD is pressed before 2nd exhale
      // 4 time it is called is when STOP is after before 2nd exhale
      // 5(final) time it is called is when RECORD is pressed before final exhale
      console.log("SHOW STATUS CALLED");
      this.button_presses++;

      if (this.button_presses > 4){
        console.log("UNREACHABLE CASE")
        return
      }

      if (this.button_presses % 2 == 0) {
        this.exhales_recorded++; // every alternate button press is after exhale
        this.instructions_displayed = true;
      }



    },
    resetCounter() {
      this.button_presses = 0;
      this.instructions_displayed = 0;
      this.exhales_recorded = 0;
    },
  }
};
</script>
