<template>
  <button
    v-if="show_stop"
    class="record__record-button stop scale btn-stop"
    @click="stop"
    :disabled="disabled"
    key="btn-stop"
  >
    <span>stop</span>
    <span class="record__record-timer">{{ time }}</span>
  </button>
  <button
    v-else-if="!show_stop"
    class="record__record-button scale btn-stop"
    @click="stop"
    :disabled="disabled"
    key="btn-stop"
  >
    <img
      src="@/assets/images/microphone.svg"
      alt="microphone"
      width="16"
      height="25"
      class="record__record-button-icon"
    />
    <span>Record</span>
  </button>
</template>

<script>


export default {
  components: { },

  mixins: [],

  data: () => ({

  }),

  props : 
    ["show_stop", "stop", "disabled", "time"]

  
};

</script>
